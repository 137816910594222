/* AddHolidays.css */

.update-holidays {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 10px;
    background-color: #f9f9f9;
}

.update-holidays h2 {
    text-align: center;
    color: #ff8800;
    margin-bottom: 20px;
}

.add-holiday-form, .holiday-list {
    margin-top: 20px;
}

.add-holiday-form form, .search-holidays {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.add-holiday-form label, .search-holidays label {
    font-weight: bold;
    margin-bottom: 10px;
}

.add-holiday-form input[type="text"], .add-holiday-form input[type="date"], .add-holiday-form select, .search-holidays input[type="text"] {
    width: 100%;
    max-width: 300px;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.add-holiday-form button, .search-holidays button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.add-holiday-form button:hover, .search-holidays button:hover {
    background-color: #0056b3;
}

.holiday-list h2 {
    text-align: center;
    color: #ff8800;
}

.holiday-list table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.holiday-list table th, .holiday-list table td {
    padding: 10px;
    border: 1px solid #ddd;
    text-align: center;
}

.holiday-list table th {
    background-color: #007bff;
    color: white;
}

.holiday-list table td {
    background-color: #f9f9f9;
}

.holiday-list table td button {
    padding: 5px 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.holiday-list table td button:hover {
    background-color: #0056b3;
}

.holiday-list .search-holidays {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.search-holidays input[type="text"] {
    padding: 10px;
    margin-right: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.search-holidays button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.search-holidays button:hover {
    background-color: #0056b3;
}

.add-holiday-form a, .holiday-list a {
    text-decoration: none;
    color: #007bff;
    display: block;
    text-align: center;
    margin-top: 20px;
}

.add-holiday-form a button, .holiday-list a button {
    background-color: #28a745;
    margin-top: 0;
}

.add-holiday-form a button:hover, .holiday-list a button:hover {
    background-color: #218838;
}


@media(max-width:1300px){
    .update-holidays{
        margin-left: 15%;
    }
}
@media(max-width:1300px){
    .holiday-list{
        margin-left: 15%;
    }
}