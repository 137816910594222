/* General Container Styles */
.assign-container {
  padding: 20px;
  font-family: 'Roboto', sans-serif;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  max-width: 900px;
  margin: 20px auto;
}

/* Heading Styles */
.assign-container h2 {
  text-align: center;
  margin-bottom: 25px;
  color: #333;
  font-size: 1.8rem;
  font-weight: 700;
}

/* Patient Info Table */
.patient-info {
  margin-bottom: 20px;
  background-color: #ffffff;
  padding: 0;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
}

.info-table {
  width: 100%;
  border-collapse: collapse;
  border-radius: 10px;
  overflow: hidden;
}

.info-table tbody tr:nth-child(odd) {
  background-color: #f8f9fa;
}

.info-table td {
  padding: 15px 20px;
  border: 1px solid #ddd;
  font-size: 1rem;
  color: #555;
}

.info-table td:first-child {
  font-weight: bold;
  color: #333;
}

.info-table td:last-child {
  text-align: left;
}

/* Assign Phlebotomist Section */
.assign-phlebotomist {
  margin-bottom: 20px;
}

.assign-phlebotomist label {
  display: block;
  margin-bottom: 10px;
  font-size: 1rem;
  font-weight: 600;
  color: #555;
}

.assign-phlebotomist .react-select__control {
  padding: 0.7rem;
  border-radius: 8px;
  border: 1px solid #ddd;
  font-size: 1rem;
  background-color: #f8f9fa;
  transition: border-color 0.2s ease;
}

.assign-phlebotomist .react-select__control:hover {
  border-color: #007bff;
}

/* Schedule Section */
.schedule-info {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.schedule-date,
.preferred-time {
  flex: 1;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
}

.schedule-date label,
.preferred-time label {
  font-weight: 600;
  color: #555;
}

.schedule-date .date-picker {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
  background-color: #f8f9fa;
  font-size: 1rem;
  transition: border-color 0.3s ease;
}

.schedule-date .date-picker:focus {
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.25);
}

/* Time Picker Styling */
.FromHoursAssignedFlexDiv,
.FromHoursAssignedFlexDivss {
  display: flex;
  gap: 15px;
}

.FromHoursAssignedFlexDiv .AssignedLabelDiv,
.FromHoursAssignedFlexDivss .AssignedLabelDiv {
  flex: 1;
}

.FromHoursAssignedFlexDiv .AssignedLabelDiv label,
.FromHoursAssignedFlexDivss .AssignedLabelDiv label {
  font-weight: 600;
  color: #555;
}

.react-select__control {
  width: 100%;
  padding: 0.7rem;
  border-radius: 5px;
  border: 1px solid #ddd;
  background-color: #f8f9fa;
  transition: border-color 0.3s ease;
}

.react-select__control:hover {
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.25);
}

/* Special Instructions */
.special-instructions {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  margin-bottom: 25px;
}

.special-instructions h3 {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 15px;
  color: #333;
}

.special-instructions ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.special-instructions li {
  font-size: 1rem;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
}

.special-instructions li input {
  margin-right: 10px;
}

/* Buttons */
.submit-button {
  display: block;
  width: 100%;
  max-width: 250px;
  padding: 12px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: 600;
  margin: 10px auto;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.submit-button:hover {
  background-color: #0056b3;
  box-shadow: 0 4px 12px rgba(0, 123, 255, 0.3);
}

.success-message {
  color: green;
  font-size: 1.1rem;
  text-align: center;
  margin-top: 15px;
  animation: fadeIn 0.5s ease-in-out;
}

.FromHoursAssignedFlexDivss{
  display: block;
  flex: 1 1;
    background-color: #ffffff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
}
/* Media Queries for Responsiveness */
@media (max-width: 1300px) {
  .schedule-info {
    flex-direction: column;
  }

  .patient-info-main {
   
    display: flex;
  }

  .submit-button {
    width: 100%;
  }
  .assign-container {
    padding: 20px;
    font-family: 'Roboto', sans-serif;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    max-width: 835px;
    margin: 20px auto;
    margin-left: 10%;
}
}

@media (max-width: 600px) {
  .assign-container {
    padding: 15px;
  }

  .assign-container h2 {
    font-size: 1.5rem;
  }

  .patient-info-item {
    font-size: 0.9rem;
  }

  .submit-button {
    font-size: 0.9rem;
    padding: 10px;
  }
}

