/* RowNavbar.css */
.row-navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: #f8f9fa;
    border-bottom: 1px solid #dee2e6;
    position: fixed;
    top: 0;
    width: calc(100% - 220px); /* Adjust width considering the left navbar */
    left: 230px; /* The width of the ColumnNavbar */
    z-index: 1000;
    transition: all 0.3s ease;
}
.navbar-username{
    margin-bottom: 1rem;
}
.row-navbar-left {
    display: flex;
    align-items: center;
}

.brand-name {
    font-size: 24px;
    font-weight: bold;
    margin-right: 20px;
    color: #343a40;
}

.row-nav a {
    margin-right: 15px;
    text-decoration: none;
    color: #333;
    font-weight: 500;
    transition: color 0.2s ease-in-out;
}

.row-nav a:hover {
    color: #007bff;
}

.row-navbar-right {
    position: relative;
}

.user-info {
    cursor: pointer;
    display: flex;
    align-items: center;
    font-weight: 500;
    color: #343a40;
    transition: color 0.2s ease-in-out;
}

.user-info:hover {
    color: #007bff;
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    right: 0;
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    padding: 10px;
    text-align: right;
    min-width: 150px;
    display: none; /* Initially hidden */
}

.dropdown-menu button {
    background: none;
    border: none;
    color: #007bff;
    cursor: pointer;
    padding: 5px 10px;
    width: 100%;
    text-align: left;
    font-weight: 500;
    transition: color 0.2s ease-in-out;
}

.dropdown-menu button:hover {
    text-decoration: underline;
    background-color: #f1f1f1;
    border-radius: 4px;
}

.row-navbar-right:hover .dropdown-menu {
    display: block; /* Show dropdown on hover */
}
@media (max-width: 1300px) {
    .row-navbar {
        flex-direction: column;
        align-items: flex-start;
        left: 0px;
        width: 100%;
    }

    .row-navbar-left, 
    .row-navbar-right {
        width: 100%;
        justify-content: space-between;
        padding: 5px 0;
    }

    .dropdown-menu {
        right: 0;
        width: 100%;
    }
}


/* Media Queries for Responsiveness */
@media (max-width: 768px) {
    .row-navbar {
        width: 100%;
        left: 0;
        padding: 10px;
    }

    .brand-name {
        font-size: 20px;
    }

    .row-nav a {
        font-size: 14px;
        margin-right: 10px;
    }

    .dropdown-menu {
        right: 10px;
    }
}

@media (max-width: 576px) {
    .row-navbar {
        flex-direction: column;
        align-items: flex-start;
    }

    .row-navbar-left, 
    .row-navbar-right {
        width: 100%;
        justify-content: space-between;
        padding: 5px 0;
    }

    .dropdown-menu {
        right: 0;
        width: 100%;
    }
}
