/* General Styles */
.contact-enquiry {
  padding: 20px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  background-color: #f8f9fa;
  width: 100%;  /* Take full width */
  max-width: 100%;  /* Ensure full width */
  margin: auto;
}

.contact-enquiry h2 {
  background: linear-gradient(90deg, #ff8a00, #e52e71);
  padding: 1.5rem;
  text-align: center;
  color: white;
  border-radius: 10px;
  font-size: 1.8rem;
}

/* Search Filters Section */
.search-filters {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  gap: 20px;
  margin-bottom: 20px;
  width: 100%;
  align-items: center;
}

.search-filters label {
  font-weight: bold;
  margin-bottom: 5px;
  color: #495057;
}

.search-input input,
.search-datepicker input,
.search-records input,
.search-select .react-select__control {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.search-input input:focus,
.search-datepicker input:focus,
.search-records input:focus,
.search-select .react-select__control--is-focused {
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  outline: none;
}

/* Select Field */
.search-select .react-select__value-container {
  padding: 10px;
}

/* Buttons */
.search-filters button {
  padding: 12px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
  width: 100%;
  max-width: 220px; /* Ensure buttons fit large screens */
}

.search-filters button:hover {
  background-color: #0056b3;
}

/* Table Section */
.table-container {
  overflow-x: auto;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  background-color: #fff;
}

table th, 
table td {
  padding: 16px;
  border: 1px solid #dee2e6;
  text-align: left;
  font-size: 15px;
  color: #495057;
}

table th {
  background-color: #e9ecef;
  font-weight: 700;
}

table tr:nth-child(even) {
  background-color: #f8f9fa;
}

table tr:hover {
  background-color: #e9ecef;
}

table a {
  color: #007bff;
  text-decoration: none;
}

table a:hover {
  text-decoration: underline;
}

/* Action Buttons */
button.manage,
button.send-info {
  padding: 8px 12px;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  text-align: center;
  transition: background-color 0.3s ease;
}

button.manage {
  background-color: #28a745;
}

button.manage:hover {
  background-color: #218838;
}

button.send-info {
  background-color: #ffc107;
  color: #212529;
}

button.send-info:hover {
  background-color: #e0a800;
}

@media(max-width:1300px) {
  .contact-enquiry{
    margin-left: 15%;
  }
  .contact-enquiry{
    max-width: 95%;
  }
}
/* Responsive Styles */
@media screen and (max-width: 767px) {
  .search-filters {
    grid-template-columns: 1fr;
    gap: 15px;
  }

  table th, table td {
    font-size: 14px;
    padding: 12px;
  }

  button.manage, 
  button.send-info {
    font-size: 13px;
    padding: 8px 10px;
  }
}

@media screen and (max-width: 480px) {
  .contact-enquiry h2 {
    font-size: 28px;
  }

  .search-filters button {
    font-size: 14px;
  }

  table th, 
  table td {
    font-size: 13px;
    padding: 10px;
  }

  button.manage, 
  button.send-info {
    font-size: 12px;
    padding: 6px 8px;
  }
}
