/* General Styles */
.career-unique__page-enquiry {
    padding: 20px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-color: #f8f9fa;
    width: 100%;  /* Take the full width of the container */
    max-width: 100%;  /* Ensure it spans the entire width */
    margin: auto;
}
.career-unique__page-enquiry h2 {
    background: linear-gradient(90deg, #ff8a00, #e52e71);
    padding: 1.5rem;
    text-align: center;
    color: white;
    border-radius: 10px;
    font-size: 1.8rem;
}



/* Filters Section */
.career-unique__search-filters {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
    gap: 20px;
    margin-bottom: 20px;
    width: 100%;  /* Take full width */
}

.career-unique__filter-item {
    display: flex;
    flex-direction: column;
    width: 100%;  /* Full width for the filter items */
}

.career-unique__filter-item label {
    margin-bottom: 8px;
    color: #495057;
    font-weight: 600;
}

.career-unique__filter-item input,
.career-unique__filter-item .career-unique__control,
.career-unique__filter-item .react-datepicker-wrapper {
    width: 100%;  /* Full width input fields */
    box-sizing: border-box;  /* Prevent overflow */
}

.career-unique__filter-buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
    align-items: center;
    width: 100%;  /* Full width for buttons */
}

.career-unique__button {
    padding: 12px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
    width: 100%;  /* Buttons take full width on smaller screens */
    max-width: 220px;  /* Limit the max width for large screens */
}

.career-unique__button:hover {
    background-color: #0056b3;
}

/* Table Section */
.career-unique__table-container {
    overflow-x: auto;
    width: 100%;
}

.career-unique__table {
    width: 100%;
    border-collapse: collapse;
    background-color: #fff;
}

.career-unique__table th,
.career-unique__table td {
    padding: 16px;
    border: 1px solid #dee2e6;
    text-align: left;
    font-size: 15px;
    color: #495057;
}

.career-unique__table th {
    background-color: #e9ecef;
    font-weight: 700;
}

.career-unique__table tbody tr:nth-child(even) {
    background-color: #f8f9fa;
}

.career-unique__table tbody tr:hover {
    background-color: #e9ecef;
}

.career-unique__table a {
    color: #007bff;
    text-decoration: none;
}

.career-unique__table a:hover {
    text-decoration: underline;
}

/* Action Buttons */
.career-unique__action-buttons {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.career-unique__manage-button,
.career-unique__info-button,
.career-unique__attend-button,
.career-unique__attended-button {
    padding: 8px 12px;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
    text-align: center;
    transition: background-color 0.3s ease;
}

.career-unique__manage-button {
    background-color: #28a745;
}

.career-unique__manage-button:hover {
    background-color: #218838;
}

.career-unique__info-button {
    background-color: #17a2b8;
}

.career-unique__info-button:hover {
    background-color: #117a8b;
}

.career-unique__attend-button {
    background-color: #ffc107;
    color: #212529;
}

.career-unique__attend-button:hover {
    background-color: #e0a800;
}

.career-unique__attended-button {
    background-color: #6c757d;
    cursor: default;
}

.career-unique__attended-row {
    background-color: #d4edda;
}

/* Input Styles */
.career-unique__filter-item input[type='text'] {
    padding: 10px;
    border: 1px solid #ced4da;
    border-radius: 4px;
    font-size: 15px;
    color: #495057;
    transition: border-color 0.3s ease;
    width: 100%;  /* Ensure input fields take full width */
}

.career-unique__filter-item input[type='text']:focus {
    border-color: #80bdff;
    outline: none;
}

/* DatePicker Styles */
.career-unique__filter-item .react-datepicker-wrapper,
.career-unique__filter-item .career-unique__control {
    font-size: 15px;
    width: 100%;  /* Ensure DatePicker takes full width */
}

.career-unique__filter-item .career-unique__control {
    border-radius: 4px;
    border-color: #ced4da;
    transition: border-color 0.3s ease;
}

.career-unique__filter-item .career-unique__control--is-focused {
    border-color: #80bdff;
    box-shadow: none;
}

.career-unique__filter-item .react-select__menu {
    font-size: 15px;
}
@media (max-width: 1200px) {
    .career-unique__page-enquiry {
        padding: 20px;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        background-color: #f8f9fa;
        width: 100%;
        max-width: 95%;
        margin: auto;
        margin-left: 15%;
    }
}

/* Responsive Styles */
@media screen and (max-width: 767px) {
    .career-unique__filter-buttons {
        grid-column: span 1;
    }

    .career-unique__table th,
    .career-unique__table td {
        font-size: 14px;
        padding: 12px;
    }


    .career-unique__button {
        font-size: 15px;
        padding: 10px 16px;
    }

    .career-unique__manage-button,
    .career-unique__info-button,
    .career-unique__attend-button,
    .career-unique__attended-button {
        font-size: 13px;
        padding: 8px 10px;
    }
}

@media screen and (max-width: 480px) {
    .career-unique__page-enquiry h2 {
        font-size: 28px;
    }

    .career-unique__button {
        font-size: 14px;
    }

    .career-unique__table th,
    .career-unique__table td {
        font-size: 13px;
        padding: 10px;
    }

    .career-unique__manage-button,
    .career-unique__info-button,
    .career-unique__attend-button,
    .career-unique__attended-button {
        font-size: 12px;
        padding: 6px 8px;
    }
}
