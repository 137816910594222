/* General container styling */
.manage-home-collection {
  padding: 20px;
  padding-top: 80px;
  font-family: 'Roboto', 'Arial', sans-serif;
  background-color: #f5f7fa;
  max-width: 1000px;
  margin: 0 auto;
  box-sizing: border-box;
  margin-left: 5vw;
}

/* Header styling */
.manage-home-collection h2 {
  text-align: center;
  color: #2c3e50;
  font-size: 2rem;
  margin-bottom: 30px;
}

/* Patient Info Section */
.patient-info {
  background-color: #fff;
  border: 1px solid #dcdfe3;
  padding: 30px;
  border-radius: 8px;
  margin-bottom: 30px;
}

/* Table styling */
.patient-info table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.patient-info td {
  padding: 10px 15px;
  border-bottom: 1px solid #ecf0f1;
}

.patient-info tr:nth-child(even) {
  background-color: #f9fbfc;
}

.patient-info strong {
  color: #2980b9;
}

/* Prescription section styling */
.prescription-actions {
  display: flex;
  gap: 10px;
}

.view-prescription-button,
.print-prescription-button,
.view-estimate-button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.2s ease;
}

.view-prescription-button {
  background-color: #4a90e2;
  color: white;
}

.print-prescription-button {
  background-color: #28a745;
  color: white;
}

.view-estimate-button {
  background-color: #6c757d;
  color: white;
}

/* Estimate section styling */
.estimate-section {
  margin-top: 20px;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.estimate-section h4 {
  margin-bottom: 15px;
  color: #2c3e50;
  font-size: 1.2rem;
}

.estimate-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.estimate-table th,
.estimate-table td {
  padding: 12px;
  border: 1px solid #dee2e6;
  text-align: left;
}

.estimate-table th {
  background-color: #e9ecef;
  font-weight: 600;
}

.estimate-table tr:nth-child(even) {
  background-color: #f8f9fa;
}

.estimate-table tr:hover {
  background-color: #f2f2f2;
}

.total-row,
.discount-row,
.final-row {
  font-weight: 500;
}

.final-row {
  background-color: #e9ecef !important;
  font-weight: 600;
}

/* Special Instructions Section */
.special-instructions {
  margin-top: 30px;
}

.special-instructions h4 {
  margin-bottom: 15px;
  font-size: 1.5rem;
  color: #2c3e50;
  border-bottom: 2px solid #2980b9;
  padding-bottom: 10px;
}

.instructions-table {
  width: 100%;
  border-collapse: collapse;
}

.instructions-table th,
.instructions-table td {
  border: 1px solid #dcdfe3;
  padding: 12px;
  text-align: left;
  font-size: 1rem;
}

.instructions-table th {
  background-color: #ecf0f1;
  font-weight: bold;
}

/* Actions Section */
.patient-info-actions,
.payment-section,
.collected-actions {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin-top: 20px;
  align-items: center;
}

.patient-info-actions label,
.payment-section label {
  font-size: 1rem;
  margin-right: 10px;
}

.patient-info-actions select,
.payment-section select,
.payment-section input {
  padding: 8px 12px;
  font-size: 1rem;
  border: 1px solid #dcdfe3;
  border-radius: 4px;
  outline: none;
  transition: border-color 0.3s ease;
}

/* Button styling */
.mARKaSCOLLEVTED {
  background-color: #00642a;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.CLEARcOLLECTED {
  background-color: #c0392b;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.submit-button {
  padding: 12px 24px;
  background-color: #27ae60;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease, transform 0.1s ease;
  margin: 5px;
}

.submit-button:hover {
  background-color: #2ecc71;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .manage-home-collection {
    padding: 15px;
    padding-top: 80px;
    margin-left: 15%;
  }

  .manage-home-collection h2 {
    font-size: 1.8rem;
  }
}

@media (max-width: 768px) {
  .manage-home-collection {
    margin-left: 30px;
  }
  
  .prescription-actions {
    flex-direction: column;
  }
  
  .patient-info-actions,
  .payment-section,
  .collected-actions {
    flex-direction: column;
    align-items: stretch;
  }
  
  .patient-info-actions label,
  .payment-section label {
    margin-bottom: 5px;
  }
  
  .view-prescription-button,
  .print-prescription-button,
  .view-estimate-button {
    width: 100%;
    margin-bottom: 5px;
  }
}

@media (max-width: 480px) {
  .manage-home-collection {
    padding: 10px;
    padding-top: 80px;
  }

  .manage-home-collection h2 {
    font-size: 1.4rem;
  }

  .patient-info {
    padding: 15px;
  }

  .estimate-table {
    font-size: 0.9rem;
  }
}