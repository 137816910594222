.update-special-instructions-container {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.update-special-instructions-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.update-special-instructions-header h2 {
    font-size: 24px;
    color: #f89c1c;
}

.update-special-instructions-back-button {
    background-color: #337ab7;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none;
}

.update-special-instructions-form-container {
    padding: 20px;
    background-color: #ffffff;
    border-radius: 8px;
}

.update-special-instructions-form-group {
    margin-bottom: 15px;
}

.update-special-instructions-form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.update-special-instructions-form-group input,
.update-special-instructions-form-group textarea {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
}

.update-special-instructions-form-group textarea {
    height: 100px;
    resize: vertical;
}

.update-special-instructions-submit-button {
    background-color: #337ab7;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
}

.error-message {
    color: red;
    margin-top: 5px;
}
