/* Table Styles */
.update-news-unique__table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 1rem;
    background-color: #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.update-news-unique__table th, 
.update-news-unique__table td {
    padding: 0.75rem 1rem;
    text-align: left;
    border: 1px solid #e9ecef;
    font-size: 1rem;
    color: #333;
}

.update-news-unique__table th {
    background-color: rgb(13,70,119);
    color: white;
    font-weight: 600;
    text-transform: uppercase;
}

.update-news-unique__table td {
    background-color: rgb(245, 245, 245);
}

/* Filter Section */
.update-news-unique__filter-container {
    background-color: rgb(245, 245, 245);
    padding: 1.5rem;
    margin-bottom: 2rem;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.update-news-unique__filter-container input {
    padding: 0.75rem 2rem;
    width: 100%;
    max-width: 600px;
    border: 1px solid #ced4da;
    border-radius: 5px;
    font-size: 1rem;
    box-sizing: border-box;
    transition: border-color 0.3s ease;
}

.update-news-unique__filter-container input:focus {
    border-color: #007bff;
    outline: none;
    box-shadow: 0 0 8px rgba(0, 123, 255, 0.25);
}

/* Button Styles */
.update-news-unique__table button {
    background-color: #007bff;
    color: white;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    font-size: 1rem;
}

.update-news-unique__table button:hover {
    background-color: #0056b3;
    box-shadow: 0 2px 4px rgba(0, 123, 255, 0.3);
}
@media(max-width:1300px) {
    .UpdateNewsMainDiv{
        margin-left: 15%;
    }
}

/* Responsive Design */
@media (max-width: 768px) {
    .update-news-unique__table th,
    .update-news-unique__table td {
        padding: 0.5rem 0.75rem;
        font-size: 0.9rem;
    }

    .update-news-unique__filter-container input {
        padding: 0.65rem 1.5rem;
        font-size: 0.9rem;
    }

    .update-news-unique__table button {
        padding: 0.5rem;
        font-size: 0.9rem;
    }
}

@media (max-width: 480px) {
    .update-news-unique__filter-container {
        padding: 1rem;
        flex-direction: column;
    }

    .update-news-unique__filter-container input {
        padding: 0.6rem;
        width: 100%;
        font-size: 0.85rem;
    }

    .update-news-unique__table th,
    .update-news-unique__table td {
        font-size: 0.85rem;
        padding: 0.4rem 0.6rem;
    }

    .update-news-unique__table button {
        padding: 0.4rem;
        font-size: 0.85rem;
    }
}
