/* Main container */
.timeslots-container {
    max-width: 800px;
    margin: 40px auto;
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
}

/* Header */
.timeslots-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.timeslots-header h2 {
    font-size: 28px;
    color: #007bff;
    font-weight: 600;
    margin: 0;
}

.back-to-menu-btn {
    background-color: #28a745;
    color: white;
    border: none;
    padding: 12px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 16px;
}

.back-to-menu-btn:hover {
    background-color: #218838;
}

/* Options for Add, Update, Delete */
.timeslots-options {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.timeslots-option {
    margin-bottom: 15px;
}

.timeslots-option button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 12px 24px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 150px;
    font-weight: 500;
}

.timeslots-option button:hover {
    background-color: #0056b3;
}

@media(max-width:1300px) {
    .timeslots-container{
        margin-left: 15%;
    }
}
/* Responsive styling for smaller screens */
@media (max-width: 768px) {
    .timeslots-options {
        flex-direction: column;
    }

    .timeslots-option button {
        width: 100%;
        font-size: 14px;
        padding: 10px;
    }

    .back-to-menu-btn {
        padding: 10px;
        font-size: 14px;
    }
}

@media (max-width: 480px) {
    .timeslots-container {
        padding: 15px;
    }

    .timeslots-header h2 {
        font-size: 24px;
    }

    .timeslots-option button {
        font-size: 14px;
        padding: 8px;
    }

    .back-to-menu-btn {
        font-size: 14px;
        padding: 8px;
    }
}
