
.EstimateParamsTable{
    width: 100%;
    background-color: rgb(237,237,237);
    border: 1px solid rgb(255, 255, 255);
}
.EstimateParamsTable tr th{
    border: 1px solid white;
}
.EstimateParamsMAinData td{
    border: .2px solid white;
    text-align: center;
}
.EstimateParamsIntusionsName{
    text-align: center;
    border: none !important;
    height: 40px;
    background-color: rgb(234 188 188);
    font-weight: 700;
    

}
.EstimateParamsIntusionsName td{
    border-color: rgb(234 188 188);
    font-weight: 600;
}
#EstimateParamsIntusionsName1{
    width: 5%;
}
.EstimateParamPageMainDiv{
    padding: 2rem 2rem;
}