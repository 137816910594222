.EstimateMianDiv{
    width: 100%;
    padding: 2rem 1rem;
    text-align: center;
}
.EstimatePageMAiN{
    text-align: center;
    margin-inline: 2rem;
}
.EstimatePageMainHeading{
    background-color: rgb(245,245,245);
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin-top: 3rem;
    border-radius: 10px;
    color: rgb(255,153,0);
    font-size: 1.3rem;
}
.EstimateTableDIv table{
    padding-inline: 5rem;
    margin-top: 2rem;
    width: 100%;
    background-color:rgb(245,245,245);
    padding-top: 2rem;
    padding-bottom: 2rem;
    border-radius: 10px;
}
#EsitmateDropdownId{
    width: 70%;
    margin-left: 1%;
}


.EstimateTableDIv table th{
   border: 1px solid rgb(75, 75, 75);
   height: 3rem;
}
.EstimateTableDIv table td{
    border: 1px solid rgb(81, 81, 81);
    height: 2rem;
 }
 .EstimateTotal td{
    background-color:rgb(0,128,128) ;
    color: white;
    font-weight: 600;
 }
 .EstimatePageMainFilters{
    display: flex;
    margin-left: 10%;
 }

 .EstimatePageMainFilters {
    display: flex;
    margin-left: 10%;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .EstimatePageMainFilters input,
  .EstimatePageMainFilters .select-container {
    flex: 1;
    min-width: 150px;
    height: 38px;
    padding: 0 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  #EsitmateDropdownId {
    width: 100%;
    margin-left: 0;
  }
  
  .EstimateMianDiv button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: rgb(0,128,128);
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .EstimateMianDiv button:hover {
    background-color: rgb(0,100,100);
  }