.add-user-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .add-user-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .add-user-header h2 {
    color: #333;
    margin: 0;
  }
  
  .back-button {
    padding: 10px 15px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .back-button:hover {
    background-color: transparent;
  }
  
  .add-user-form {
    background-color: #ffffff;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .form-row {
    display: flex;
    gap: 20px;
    margin-bottom: 15px;
  }
  
  .form-group {
    flex: 1;
    margin-bottom: 20px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
    color: #555;
    font-weight: bold;
  }
  
  .form-group input,
  .form-group select,
  .form-group textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .form-group textarea {
    resize: vertical;
    min-height: 100px;
  }
  
  .password-input {
    position: relative;
  }
  
  .password-input input {
    width: 100%;
    padding-right: 60px; /* Increased right padding */
  }
  
  .toggle-password {
    position: relative;
    right: 10px;
    top: 50%;
    transform: translateY(0%);
    background: none;
    border: none;
    cursor: pointer;
    color: #007bff;
    z-index: 1; /* Ensure the button is above the input */
    padding: 5px 10px; /* Added padding to increase clickable area */
  }
  
  .error-message {
    color: #dc3545;
    font-size: 14px;
    margin-top: 5px;
  }
  
  .success-message {
    background-color: #d4edda;
    color: #155724;
    padding: 15px;
    border-radius: 5px;
    margin-bottom: 20px;
  }
  
  .submit-button {
    width: 100%;
    padding: 12px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
  }
  
  .submit-button:hover {
    background-color: transparent;
  }
  
  .submit-button:disabled {
    background-color: #6c757d;
    cursor: not-allowed;
  }
  
  .password-strength {
    margin-top: 5px;
    font-size: 14px;
  }
  
  .password-strength.weak {
    color: #dc3545;
  }
  
  .password-strength.moderate {
    color: #ffc107;
  }
  
  .password-strength.strong {
    color: #28a745;
  }
  
  .profile-preview {
    max-width: 200px;
    max-height: 200px;
    margin-top: 10px;
  }
  
  @media (max-width: 600px) {
    .form-row {
      flex-direction: column;
      gap: 0;
    }
  }