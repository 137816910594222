.add-special-instructions-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
}

.add-special-instructions-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.add-special-instructions-header h2 {
    color: orange;
}

.add-special-instructions-back-button {
    background-color: #337ab7;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 4px;
    cursor: pointer;
}

.add-special-instructions-form-container {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.add-special-instructions-form-container h3 {
    margin-bottom: 20px;
    color: #337ab7;
}

.add-special-instructions-form-group {
    margin-bottom: 15px;
}

.add-special-instructions-form-group label {
    display: block;
    margin-bottom: 5px;
    color: #333;
}

.add-special-instructions-form-group input,
.add-special-instructions-form-group textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.add-special-instructions-form-group textarea {
    height: 100px;
    resize: vertical;
}

.add-special-instructions-submit-button {
    background-color: #337ab7;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 4px;
    cursor: pointer;
    display: block;
    margin: 0 auto;
}
