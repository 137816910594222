.delete-special-instructions-container {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.delete-special-instructions-header {
    margin-bottom: 20px;
}

.delete-special-instructions-header h2 {
    font-size: 24px;
    margin-bottom: 10px;
    color: #f89c1c;
}

.delete-special-instructions-back-button {
    padding: 10px 20px;
    background-color: #337ab7;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.delete-special-instructions-list-container {
    margin-bottom: 20px;
}

.special-instructions-table {
    width: 100%;
    border-collapse: collapse;
}

.special-instructions-table th,
.special-instructions-table td {
    padding: 10px;
    border: 1px solid #ccc;
    text-align: left;
}

.special-instructions-table th {
    background-color: #337ab7;
    color: white;
}

.special-instructions-table td {
    background-color: #f9f9f9;
}

.select-button {
    padding: 5px 10px;
    background-color: #d9534f;
    color: white;
    border: none;
    border-radius: 3px;
    cursor: pointer;
}

.delete-special-instructions-confirm-container {
    margin-top: 20px;
}

.delete-special-instructions-delete-button {
    padding: 10px 20px;
    background-color: #d9534f;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}
