.not-authorized-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f0f4f8;
    font-family: 'Arial', sans-serif;
    text-align: center;
    padding: 20px;
}

.not-authorized-container h1 {
    font-size: 3rem;
    color: #ff4a4a;
    margin-bottom: 20px;
}

.not-authorized-container p {
    font-size: 1.25rem;
    color: #333;
    max-width: 600px;
}

.not-authorized-container a {
    margin-top: 30px;
    padding: 10px 20px;
    background-color: #ff4a4a;
    color: white;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.not-authorized-container a:hover {
    background-color: #ff1f1f;
}
