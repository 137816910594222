/* Container Styling */
.news-container {
    width: 100%;
    max-width: 1200px;
    margin: 2rem auto;
    padding: 2rem;
    background-color: #f8f9fa;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  /* Heading Styling */
  .news-heading h1 {
    font-size: 2.2rem;
    color: #343a40;
    font-weight: 700;
    margin-bottom: 2rem;
  }
  
  /* Actions Section */
  .news-actions {
    display: flex;
    justify-content: center;
    gap: 1.5rem;
    flex-wrap: wrap;
  }
  
  .news-action-button {
    text-align: center;
  }
  
  /* Button Styles */
  .news-btn {
    padding: 0.75rem 2rem;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 1.1rem;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
  }
  
  .news-btn:hover {
    background-color: #0056b3;
    box-shadow: 0 2px 4px rgba(0, 123, 255, 0.3);
  }
  
  .delete-btn {
    background-color: #dc3545;
  }
  
  .delete-btn:hover {
    background-color: #c82333;
    box-shadow: 0 2px 4px rgba(220, 53, 69, 0.3);
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .news-heading h1 {
      font-size: 1.8rem;
    }
  
    .news-btn {
      font-size: 1rem;
      padding: 0.65rem 1.5rem;
    }
  
    .news-actions {
      flex-direction: column;
      gap: 1rem;
    }
  }
  
  @media (max-width: 480px) {
    .news-heading h1 {
      font-size: 1.5rem;
    }
  
    .news-btn {
      font-size: 0.9rem;
      padding: 0.6rem 1.2rem;
    }
  }
  