/* Container styling */
.article-crm-container {
    max-width: 1200px;
    margin: 40px auto;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
}

/* Header styling */
.article-crm-header h1 {
    font-size: 28px;
    color: #ff7f00;
    margin-bottom: 30px;
    font-weight: 600;
}

/* Button container */
.article-crm-buttons {
    display: flex;
    justify-content: center;
    gap: 20px;  /* Spacing between buttons */
}

/* Button styling */
.article-crm-button {
    display: inline-block;
}

.article-crm-button button {
    background-color: #007bff;
    color: white;
    padding: 12px 20px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.article-crm-button button:hover {
    background-color: #0056b3;
}

/* Responsive design */
@media (max-width: 768px) {
    .article-crm-buttons {
        flex-direction: column;
        gap: 15px;
    }

    .article-crm-button button {
        width: 100%;
        max-width: 300px;
        margin: 0 auto;
    }
}
