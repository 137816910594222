/* General Styling */
body {
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.BookingMainTable {
  padding: 1rem;
  background-color: #f9fafb;
  width: 80vw;
}

h2 {
  text-align: center;
  color: #0d4677;
  margin-bottom: 1rem;
}

.SearchFilter {
  margin-bottom: 1.5rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 1rem;
}

.filter-row {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.filter-row label {
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.filter-row select,
.filter-row input {
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
}

.filter-row button {
  padding: 0.75rem 1rem;
  background-color: #0d4677;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.filter-row button:hover {
  background-color: #094060;
}

/* Table Container for Horizontal Scrolling */
.forscrollableTable {
  width: 100%;
  overflow-x: auto; /* Enable horizontal scrolling */
}

.BookingTable {
  width: 100%;
  border-collapse: collapse;
  background-color: white;
}

.BookingTable th,
.BookingTable td {
  padding: 0.75rem;
  border: 1px solid #ddd;
  text-align: left;
  font-size: 14px;
  color: black;
}

.BookingTable th {
  background-color: #0d4677;
  color: white;
  font-weight: 600;
}

.BookingsButton button {
  background-color: #0d4677;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 0.5rem 0.75rem;
  margin-bottom: 0.25rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.BookingsButton button:hover {
  background-color: #094060;
}

.filter-row {
  width: 48%;
}

.BookingMainTable h2 {
  background: linear-gradient(90deg, #ff8a00, #e52e71);
  padding: 1.5rem;
  text-align: center;
  color: white;
  border-radius: 10px;
  font-size: 1.8rem;
}

@media(max-width:1300px){
  .BookingMainTable {
    margin-left: 15%;
  }
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .BookingMainTable {
    padding: 1rem;
    background-color: #f9fafb;
    width: 95vw;
  }
}

.BookingsWhatsapp a {
  color: #25d366;
  text-decoration: none;
}

.BookingsWhatsapp a:hover {
  text-decoration: underline;
}

.BookingsTest svg {
  margin-right: 0.5rem;
}

/* Background color classes applied to <tr> elements */
.my-bookings-table tr {
  background-color: transparent; /* Ensure no default background colors are applied */
}

/* Remove any alternate row coloring */
.my-bookings-table tr:nth-child(even),
.my-bookings-table tr:nth-child(odd) {
  background-color: transparent;
}

/* Background color classes within the specific table */
.my-bookings-table tr.default-bg {
  background-color: white;
}

.my-bookings-table tr.orange-bg {
  background-color: orange;
}

.my-bookings-table tr.red-bg {
  background-color: red;
}

.my-bookings-table tr.darkpurple-bg {
  background-color: MediumPurple; /* Dark purple */
}

.my-bookings-table tr.lightpink-bg {
  background-color: lightpink;
}

.my-bookings-table tr.lightpurple-bg {
  background-color: LightSteelBlue; /* Light purple */
}

/* Remove hover effects for this table */
.my-bookings-table tr:hover,
.my-bookings-table tr td:hover {
  background-color: inherit;
}


.my-bookings-table tr.default-bg:hover {
  background-color: white;
}

.my-bookings-table tr.orange-bg:hover {
  background-color: orange;
}

.my-bookings-table tr.red-bg:hover {
  background-color: red;
}

.my-bookings-table tr.darkpurple-bg:hover {
  background-color: MediumPurple; /* Dark purple */
}

.my-bookings-table tr.lightpink-bg:hover {
  background-color: lightpink;
}

.my-bookings-table tr.lightpurple-bg:hover {
  background-color: LightSteelBlue; /* Light purple */
}



.bookings-cancel-modal {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0,0,0,0.4);
  display: flex;
  justify-content: center;
  align-items: center;
}

.bookings-cancel-modal-content {
  background-color: #fefefe;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 500px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.bookings-cancel-modal-content h2 {
  margin-top: 0;
  color: #333;
  font-size: 1.5em;
  margin-bottom: 15px;
}

.bookings-cancel-modal-content p {
  margin-bottom: 10px;
  color: #666;
}

.bookings-cancel-modal-content select {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  color: #333;
  background-color: #fff;
}

.bookings-cancel-modal-content input[type="text"] {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  color: #333;
}

.bookings-cancel-modal-buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.bookings-cancel-modal-buttons button {
  padding: 10px 15px;
  margin-left: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.bookings-cancel-modal-buttons button:first-child {
  background-color: #dc3545;
  color: white;
}

.bookings-cancel-modal-buttons button:first-child:hover {
  background-color: #c82333;
}

.bookings-cancel-modal-buttons button:last-child {
  background-color: #6c757d;
  color: white;
}

.bookings-cancel-modal-buttons button:last-child:hover {
  background-color: #5a6268;
}


/* Logs Modal Overlay */
.logs-modal {
  position: fixed;
  z-index: 1001; /* Ensure it's above other elements */
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0,0,0,0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Logs Modal Content */
.logs-modal-content {
  background-color: #fff;
  padding: 20px 25px;
  border-radius: 8px;
  width: 90%;
  max-width: 800px;
  box-shadow: 0 5px 15px rgba(0,0,0,0.3);
  animation: fadeIn 0.3s ease-in-out;
}

/* Modal Header */
.logs-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.logs-modal-header h2 {
  margin: 0;
  color: #0d4677;
}

.close-log-modal-button {
  background: none;
  border: none;
  font-size: 1.5rem;
  color: #888;
  cursor: pointer;
  transition: color 0.2s ease-in-out;
}

.close-log-modal-button:hover {
  color: #000;
}

/* Modal Body */
.logs-modal-body {
  max-height: 60vh;
  overflow-y: auto;
  padding-right: 10px;
}

.logs-table {
  width: 100%;
  border-collapse: collapse;
}

.logs-table th,
.logs-table td {
  padding: 12px 15px;
  border: 1px solid #ddd;
  text-align: left;
  vertical-align: top;
}

.logs-table th {
  background-color: #0d4677;
  color: #fff;
  position: sticky;
  top: 0;
  z-index: 2;
}

.logs-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.logs-table tr:hover {
  background-color: #f1f1f1;
}

.username {
  color: #555;
  font-size: 0.9em;
}

.no-logs-message {
  text-align: center;
  color: #555;
  font-style: italic;
  padding: 20px 0;
}

/* Modal Footer */
.logs-modal-footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
}

.close-log-button {
  padding: 8px 16px;
  background-color: #0d4677;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.close-log-button:hover {
  background-color: #094060;
}

/* Animations */
@keyframes fadeIn {
  from { opacity: 0; transform: translateY(-20px); }
  to { opacity: 1; transform: translateY(0); }
}

/* Responsive Design */
@media (max-width: 600px) {
  .logs-modal-content {
    padding: 15px 20px;
  }

  .logs-table th,
  .logs-table td {
    padding: 10px 12px;
  }

  .close-log-modal-button {
    font-size: 1.2rem;
  }

  .close-log-button {
    padding: 6px 12px;
    font-size: 0.9rem;
  }
}
