/* Main Container */
.add-news-unique__container {
    width: 100%;
    max-width: 1200px;
    margin: 2rem auto;
    padding: 2rem;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

/* Header Section */
.add-news-unique__header {
    text-align: center;
    margin-bottom: 2rem;
}

.add-news-unique__header h2 {
    font-size: 2rem;
    color: #ff8a00;
    font-weight: 600;
}

/* Form Section */
.add-news-unique__form {
    width: 100%;
}

.add-news-unique__form label {
    margin-bottom: 0.5rem;
    font-weight: 500;
    display: block;
    color: #333;
}

/* Input Fields */
.add-news-unique__form input[type="text"],
.add-news-unique__form input[type="date"],
.add-news-unique__form input[type="url"],
.add-news-unique__form input[type="file"],
.add-news-unique__form select,
.add-news-unique__form textarea {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #ced4da;
    border-radius: 5px;
    font-size: 1rem;
    margin-bottom: 1rem;
    box-sizing: border-box;
    transition: border-color 0.3s ease;
}

.add-news-unique__form input:focus,
.add-news-unique__form select:focus,
.add-news-unique__form textarea:focus {
    border-color: #007bff;
    outline: none;
    box-shadow: 0 0 8px rgba(0, 123, 255, 0.25);
}

/* Flex Layout */
.add-news-unique__flex-row {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
}

.add-news-unique__flex-column {
    flex: 1;
}

/* Textarea */
.add-news-unique__form textarea {
    resize: vertical;
    height: 150px;
}

/* Button Styles */
.add-news-unique__button-container {
    text-align: center;
    margin-top: 2rem;
}

.add-news-unique__button-container button {
    padding: 0.75rem 1.5rem;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 1.1rem;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.add-news-unique__button-container button:hover {
    background-color: #0056b3;
    box-shadow: 0 4px 10px rgba(0, 123, 255, 0.3);
}

/* Success and Error Messages */
.success-message,
.error-message {
    text-align: center;
    font-weight: 500;
    padding: 0.75rem;
    margin-bottom: 1.5rem;
    border-radius: 5px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
}

.success-message {
    background-color: #d4edda;
    color: #155724;
    border: 1px solid #c3e6cb;
}

.error-message {
    background-color: #f8d7da;
    color: #721c24;
    border: 1px solid #f5c6cb;
}
@media(max-width:1300px){
    .add-news-unique__container{
        margin-left: 15%;
        width: 90%;
    }
}

/* Responsive Styles */
@media (max-width: 768px) {
    .add-news-unique__flex-row {
        flex-direction: column;
    }

    .add-news-unique__header h2 {
        font-size: 1.75rem;
    }

    .add-news-unique__button-container button {
        width: 100%;
    }
}

@media (max-width: 480px) {
    .add-news-unique__form input,
    .add-news-unique__form select,
    .add-news-unique__form textarea {
        font-size: 0.9rem;
        padding: 0.65rem 0.75rem;
    }

    .add-news-unique__button-container button {
        padding: 0.65rem;
        font-size: 1rem;
    }
}
