/* General form container */
.add-holiday-form {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    background-color: #f9f9f9;
    animation: fadeIn 0.5s ease-in-out;
}

/* Form title */
.add-holiday-form h2 {
    text-align: center;
    color: #ff8800;
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: 600;
}

/* Form layout */
.add-holiday-form form {
    display: flex;
    flex-direction: column;
}

/* Form group styling */
.form-group {
    margin-bottom: 20px;
}

.form-group label {
    font-weight: bold;
    margin-bottom: 8px;
    display: block;
    color: #333;
}

.form-group input[type="text"],
.form-group input[type="date"],
.form-group select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    transition: border-color 0.3s;
}

.form-group input:focus,
.form-group select:focus {
    border-color: #007bff;
    outline: none;
}

/* Button styling */
.add-holiday-form button {
    padding: 12px 20px;
    background-color: #007bff;
    color: white;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 100%;
}

.add-holiday-form button:hover {
    background-color: #0056b3;
}

/* Back button styling */
.add-holiday-form .back-button {
    background-color: #28a745;
    margin-top: 20px;
}

.add-holiday-form .back-button:hover {
    background-color: #218838;
}

@media (max-width: 1300px) {
    .add-holiday-form{
        margin-left: 20%;
    }
}
/* Responsive styling */
@media (max-width: 768px) {
    .add-holiday-form {
        padding: 15px;
    }

    .add-holiday-form h2 {
        font-size: 22px;
    }

    .add-holiday-form button {
        font-size: 14px;
    }
}

/* Animation */
@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
