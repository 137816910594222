/* General container styling */
.update-user-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  /* Header styling */
  .update-user-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .update-user-header h2 {
    margin: 0;
    font-size: 24px;
    color: #333;
  }
  
  /* Button styling */
  .back-button,
  .filter-button,
  .select-button,
  .update-button {
    padding: 10px 15px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .back-button:hover,
  .filter-button:hover,
  .select-button:hover,
  .update-button:hover {
    background-color: #0056b3;
  }
  
  /* Filters section styling */
  .update-user-filters {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
    flex-wrap: wrap;
  }
  
  .filter-group {
    flex: 1;
    min-width: 250px;
  }
  
  .filter-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .filter-group input,
  .filter-group select {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  /* User table styling */
  .user-table {
    overflow-x: auto;
    margin-bottom: 30px;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
  }
  
  th,
  td {
    border: 1px solid #ddd;
    padding: 12px;
    text-align: left;
    vertical-align: top;
  }
  
  th {
    background-color: #f2f2f2;
  }
  
  .icon {
    margin-right: 5px;
  }
  
  .whatsapp {
    margin-left: 10px;
  }
  
  .user-image {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 50%;
  }
  
  /* Update form styling */
  .update-form {
    background-color: #fafafa;
    padding: 20px;
    border-radius: 5px;
  }
  
  .update-form h3 {
    margin-top: 0;
    margin-bottom: 20px;
    color: #333;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .form-group input,
  .form-group textarea,
  .form-group select {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .form-group textarea {
    resize: vertical;
    height: 80px;
  }
  
  .password-input {
    position: relative;
  }
  
  .toggle-password {
    position: relative;
    right: 10px;
    top: 50%;
    transform: translateY(0%);
    background: none;
    border: none;
    cursor: pointer;
    color: #555;
  }
  
  .update-message {
    margin-top: 15px;
    padding: 10px;
    border-radius: 4px;
    background-color: #d4edda;
    color: #155724;
  }
  
  /* Image preview styling */
  .preview-image {
    max-width: 200px;
    max-height: 200px;
    margin-top: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  /* Links in form group */
  .form-group a {
    display: inline-block;
    margin-top: 10px;
    color: #007bff;
    text-decoration: none;
  }
  
  .form-group a:hover {
    text-decoration: underline;
  }
  
  /* Media queries for responsiveness */
  @media (max-width: 768px) {
    .update-user-filters {
      flex-direction: column;
    }
  
    .update-user-header {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .back-button {
      margin-top: 10px;
    }
  }
  