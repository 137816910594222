/* General Container Styling */
.delete-user-unique__container {
    max-width: 1200px;
    margin: 2rem auto;
    padding: 2rem;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

/* Heading */
.delete-user-unique__container h2 {
    font-size: 2rem;
    font-weight: 600;
    color: #ff8a00;
    margin-bottom: 1.5rem;
    text-align: center;
}

/* Error and Success Messages */
.delete-user-unique__error-message, 
.delete-user-unique__success-message {
    text-align: center;
    font-weight: 500;
    padding: 0.75rem;
    margin-bottom: 1.5rem;
    border-radius: 5px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
}

.delete-user-unique__error-message {
    background-color: #f8d7da;
    color: #721c24;
    border: 1px solid #f5c6cb;
}

.delete-user-unique__success-message {
    background-color: #d4edda;
    color: #155724;
    border: 1px solid #c3e6cb;
}

/* Table Styles */
.delete-user-unique__table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 2rem;
}

.delete-user-unique__table thead {
    background-color: #007bff;
    color: white;
}

.delete-user-unique__table thead th {
    padding: 1rem;
    text-align: left;
    font-size: 1rem;
    text-transform: uppercase;
}

.delete-user-unique__table tbody tr:nth-child(even) {
    background-color: #f1f1f1;
}

.delete-user-unique__table tbody tr:hover {
    background-color: #e9ecef;
}

.delete-user-unique__table tbody td {
    padding: 1rem;
    font-size: 1rem;
    border-bottom: 1px solid #dee2e6;
}

/* Checkbox */
.delete-user-unique__checkbox {
    width: 1.25rem;
    height: 1.25rem;
}

/* Delete Button */
.delete-user-unique__button {
    display: block;
    width: 100%;
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    font-weight: 600;
    color: white;
    background-color: #dc3545;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.delete-user-unique__button:disabled {
    background-color: #6c757d;
    cursor: not-allowed;
}

.delete-user-unique__button:hover:enabled {
    background-color: #c82333;
    box-shadow: 0 4px 10px rgba(220, 53, 69, 0.2);
}

/* Responsive Styling */
@media (max-width: 768px) {
    .delete-user-unique__table thead th {
        font-size: 0.9rem;
        padding: 0.75rem;
    }

    .delete-user-unique__table tbody td {
        font-size: 0.9rem;
        padding: 0.75rem;
    }

    .delete-user-unique__container {
        padding: 1.5rem;
    }

    .delete-user-unique__container h2 {
        font-size: 1.75rem;
    }

    .delete-user-unique__button {
        padding: 0.75rem;
        font-size: 0.9rem;
    }
}

@media (max-width: 480px) {
    .delete-user-unique__container h2 {
        font-size: 1.7rem;
    }

    .delete-user-unique__button {
        padding: 0.75rem;
        font-size: 0.9rem;
    }

    .delete-user-unique__table thead th, 
    .delete-user-unique__table tbody td {
        padding: 0.75rem;
    }
}
