.add-packages {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    font-family: Arial, sans-serif;
    background-color: #f0f4f8;
    color: #333;
}

.add-packages h1 {
    color: #004080;
    font-size: 2em;
    margin-bottom: 20px;
}

.add-packages form {
    width: 50%;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 20px;
    background-color: #fff;
}

.form-group {
    margin-bottom: 15px;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.form-group input,
.form-group textarea {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

.form-group textarea {
    resize: vertical;
    height: 100px;
}

.form-group input[type="number"] {
    width: calc(100% - 16px); /* Adjusting for padding */
}

.tests-list {
    display: block;
    max-height: 200px;
    overflow-y: auto;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px;
    background-color: #fff;
    margin-top: 10px;
}

.test-item {
    display: flex;
    align-items: center;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: #f9f9f9;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: background-color 0.3s;
    margin-bottom: 10px;
    width: 100%;
}

.test-item:hover {
    background-color: #e6e6e6;
}

.test-item input {
    margin-right: 10px;
}

.test-item span {
    font-weight: normal;
    overflow: hidden;
    text-overflow: ellipsis;
}

.note-item {
    display: block;
    align-items: center;
    margin-bottom: 10px;
}

.note-item input {
    flex: 1;
    margin-right: 10px;
}

.note-item button {
    background-color: #004080;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 5px 10px;
    cursor: pointer;
}

.note-item button:hover {
    background-color: #003060;
}

button {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #004080;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1em;
    transition: background-color 0.3s;
}

button:hover {
    background-color: #003060;
}

.error {
    color: red;
    font-size: 0.875em;
}

.back-button {
    margin-bottom: 20px;
    padding: 10px;
    background-color: #004080;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1em;
    text-align: center;
    text-decoration: none;
    transition: background-color 0.3s;
}

.back-button:hover {
    background-color: #003060;
}
