/* General Styling */
.booking-form-container {
    padding: 20px;
    background-color: #f9f9f9;
}

.booking-form-container h2 {
    background: linear-gradient(90deg, #ff8a00, #e52e71);
    padding: 1.5rem;
    text-align: center;
    color: white;
    border-radius: 10px;
    font-size: 1.8rem;
}

.booking-form-container form {
    width: 75vw;
    padding: 2rem;
    background: white;
    border-radius: 15px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    margin-inline: auto;
}

.booking-form-container .row1 {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.booking-form-container .input-Feild {
    width: 45%;
    margin-bottom: 1.5rem;
}

.booking-form-container .input-Feild input,
.booking-form-container .input-Feild select,
.booking-form-container #time_slots,
.booking-form-container .css-b62m3t-container {
    width: 100%;
    padding: 0.7rem;
    border: 1px solid #d1d1d1;
    border-radius: 8px;
    box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.05);
}

.booking-form-container .input-Feild input:focus,
.booking-form-container .input-Feild select:focus,
.booking-form-container .css-b62m3t-container:focus-within {
    border-color: #ff8a00;
    box-shadow: 0 0 5px rgba(255, 138, 0, 0.5);
    outline: none;
}

.booking-form-container .input-Feild label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: bold;
    color: #333;
}

/* Prescription Input Styling */
.booking-form-container .input-Feild input[type="file"] {
    padding: 0.5rem;
    border: none;
}

.booking-form-container #inputtextarea textarea {
    width: 100%;
    padding: 1rem;
    border-radius: 8px;
    border: 1px solid #d1d1d1;
    resize: none;
}

/* Selected Test Packages Table */
.booking-form-container .selected-packages-table table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 2rem;
}

.booking-form-container .selected-packages-table th,
.booking-form-container .selected-packages-table td {
    border: 1px solid #ddd;
    padding: 1rem;
    text-align: left;
}

.booking-form-container .selected-packages-table th {
    background-color: #f2f2f2;
    font-weight: bold;
}

.booking-form-container .EstimateTotal td {
    font-weight: bold;
    background-color: #f7f7f7;
}

/* Add Button Styling */
.booking-form-container .AddButtonInAddBooking {
    text-align: center;
}

.booking-form-container .AddButtonInAddBooking button {
    background-color: #ff8a00;
    color: white;
    font-size: 1rem;
    padding: 0.7rem 2rem;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.booking-form-container .AddButtonInAddBooking button:hover {
    background-color: #e52e71;
}

.booking-form-container .error-message {
    color: red;
    font-size: 0.9em;
}
.thank-you-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .thank-you-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
  }
@media(max-width:1300px){
    .booking-form-container{
        margin-left: 15%;
        padding-right: 10%;
    }
    .booking-form-container form{
        width: 80%;
    }
}

/* Media Queries for Mobile Responsiveness */
@media screen and (max-width: 1000px) {
    .booking-form-container form {
        width: 95%;
        padding: 1.5rem;
        padding-right: 3rem;
    }

    .booking-form-container .row1 {
        flex-direction: column;
    }

    .booking-form-container .input-Feild {
        width: 100%;
        margin-bottom: 1.5rem;
    }

    .booking-form-container #inputtextarea textarea {
        width: 100%;
    }
}

@media screen and (max-width: 600px) {
    .booking-form-container h2 {
        font-size: 1.5rem;
        padding: 1rem;
    }

    .booking-form-container .AddButtonInAddBooking button {
        width: 100%;
        font-size: 1rem;
    }

    .booking-form-container .selected-packages-table table,
    .booking-form-container .selected-packages-table th,
    .booking-form-container .selected-packages-table td {
        font-size: 0.9rem;
        padding: 0.5rem;
    }
}
/* Modal Overlay */
.patient-modal-overlay {
    background-color: rgba(0, 0, 0, 0.75);
  }
  
  /* Modal Content */
  .patient-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    width: 80%;
    max-width: 600px;
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    overflow: auto;
    max-height: 80vh;
  }
  
  /* Close Button for Modal */
  .close-modal-button {
    position: absolute;
    top: 10px;
    right: 15px;
    background: transparent;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
  }
  
  /* Patient List Styles */
  .patient-list {
    margin-top: 20px;
  }
  
  .patient-item {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-bottom: 10px;
    cursor: pointer;
    transition: background 0.3s;
  }
  
  .patient-item:hover {
    background-color: #f0f0f0;
  }
  
  /* Success Modal Styles */
  .thank-you-modal-overlay {
    background-color: rgba(0, 0, 0, 0.75);
  }
  
  .thank-you-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    width: 50%;
    max-width: 400px;
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
  }
  
  .thank-you-modal h2 {
    margin-bottom: 10px;
  }
  
  .thank-you-modal p {
    margin-bottom: 10px;
  }
  