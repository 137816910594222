/* General container */
.CRMBlog-container {
    max-width: 1200px;
    margin: 20px auto;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

/* Header styling */
.CRMBlog-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.CRMBlog-header h1 {
    font-size: 28px;
    color: #ff7f00;
    margin: 0;
    font-weight: 600;
}

.CRMBlog-back-button {
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    font-size: 14px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.CRMBlog-back-button:hover {
    background-color: #0056b3;
}

/* Form title */
.CRMBlog-form-title {
    text-align: center;
    font-size: 24px;
    color: #ff7f00;
    margin-bottom: 20px;
    font-weight: 600;
}

/* Form styling */
.CRMBlog-form {
    display: flex;
    flex-direction: column;
}

.CRMBlog-form-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 20px;
}

.CRMBlog-form-group {
    flex: 1 1 100%;
    margin-bottom: 20px;
}

@media (min-width: 768px) {
    .CRMBlog-form-group {
        flex: 1 1 48%;
    }
}

.CRMBlog-form-group label {
    display: block;
    margin-bottom: 8px;
    font-size: 14px;
    color: #666;
    font-weight: 600;
}

.CRMBlog-form-group input,
.CRMBlog-form-group textarea {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ddd;
    font-size: 14px;
    color: #333;
    background-color: #f9f9f9;
    transition: border-color 0.3s ease;
}

.CRMBlog-form-group input:focus,
.CRMBlog-form-group textarea:focus {
    border-color: #007bff;
    outline: none;
    background-color: #fff;
}

.CRMBlog-form-group input[type="file"] {
    padding: 6px;
}

.CRMBlog-form-group textarea {
    resize: vertical;
    min-height: 120px;
}

/* Image preview */
.CRMBlog-form-group img {
    display: block;
    margin-top: 10px;
    max-width: 100px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Submit button */
.CRMBlog-submit-button {
    background-color: #28a745;
    color: #fff;
    padding: 15px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-weight: 600;
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
}

.CRMBlog-submit-button:hover {
    background-color: #218838;
}

.CRMBlog-form-group input{
    width: 80%!important;
}
/* Content group styling */
.CRMBlog-content-group {
    margin-bottom: 30px;
    flex-basis: 100%;
}

/* Error messages */
.input-error {
    border-color: #dc3545;
    background-color: #f8d7da;
}

.error-message {
    color: #dc3545;
    font-size: 12px;
    margin-top: 5px;
}
@media (max-width: 1300px) {
    .CRMBlog-container{
        margin-left: 15%!important;
    }
}
/* Responsive design */
@media (max-width: 768px) {
    .CRMBlog-form-row {
        flex-direction: column;
    }

    .CRMBlog-form-group {
        margin-right: 0;
    }

    .CRMBlog-submit-button {
        width: 100%;
        padding: 15px;
    }
}
