.add-time-slots {
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 10px;
    background-color: #f9f9f9;
}

.add-time-slots h2 {
    text-align: center;
    color: #0056b3; /* Changed color to a more formal blue */
    margin-bottom: 20px;
}

.back-button {
    display: block;
    margin: 0 auto 20px auto;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.back-button:hover {
    background-color: #0056b3;
}

.add-time-slots h3 {
    text-align: center;
    color: #333; /* Changed to a neutral color for the subheading */
    margin-bottom: 20px;
}

.form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    width: 100%; /* Ensure the form groups take full width */
}

.form-group label {
    font-weight: bold;
    margin-bottom: 5px;
    color: #333; /* Consistent label color */
}

.form-group input, 
.form-group select {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
}

.routine-day-time-slots, 
.routine-time-slots {
    margin-bottom: 30px;
    border: 1px solid #007bff; /* Added border for separation */
    padding: 10px;
    border-radius: 5px;
    background-color: #e7f1ff; /* Light blue background */
}

.routine-day-time-slots h4,
.routine-time-slots h4 {
    color: #007bff;
    margin-bottom: 10px;
    font-size: 18px;
    text-transform: uppercase; /* Added uppercase style */
}

.note {
    color: red;
    font-size: 14px;
    margin-top: 10px;
    font-weight: bold; /* Added bold to emphasize the note */
}

.before-routine-time-slots,
.after-routine-time-slots {
    margin-bottom: 30px;
}

.view-button {
    display: inline-block; /* Inline-block to align with other buttons */
    margin: 10px 5px;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px; /* Increased font size for better readability */
}

.view-button:hover {
    background-color: #0056b3;
}

.submit-button {
    display: inline-block;
    margin: 20px 5px;
    padding: 10px 20px;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.submit-button:hover {
    background-color: #218838;
}

.generated-time-slots {
    margin-top: 30px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #f1f1f1; /* Slightly darker background for the slot list */
}

.generated-time-slots h4 {
    color: #007bff;
    margin-bottom: 10px;
    font-size: 18px;
}

.generated-time-slots ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.generated-time-slots li {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-bottom: 5px;
    background-color: #ffffff; /* White background for better contrast */
    font-size: 16px; /* Increased font size for better readability */
    text-align: center; /* Centered text for uniformity */
}

.form-group input[type="time"] {
    max-width: 200px; /* Added max-width for time inputs */
}

@media(max-width:1300px){
    .add-time-slots{
        margin-left: 15%;
    }
}