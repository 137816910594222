.update-time-slots {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.slot-list {
    margin-bottom: 20px;
}

.slot-list ul {
    list-style-type: none;
    padding: 0;
}

.slot-list ul li {
    padding: 10px;
    margin: 5px 0;
    background-color: #e6e6e6;
    border-radius: 4px;
    cursor: pointer;
}

.slot-list ul li:hover {
    background-color: #d4d4d4;
}

.slot-edit-form {
    margin-top: 20px;
}

.slot-edit-form h3 {
    margin-bottom: 20px;
}

.form-group {
    margin-bottom: 15px;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.form-group input,
.form-group select {
    width: 100%;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ccc;
}

.view-button, .save-button, .back-button {
    margin-right: 10px;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.view-button:hover, .save-button:hover, .back-button:hover {
    background-color: #0056b3;
}

.routine-day-time-slots,
.before-routine-time-slots,
.after-routine-time-slots {
    background-color: #e3f2fd;
    padding: 15px;
    margin-bottom: 20px;
    border-radius: 4px;
}

.routine-day-time-slots h4,
.before-routine-time-slots h4,
.after-routine-time-slots h4 {
    color: #0d47a1;
}

.generated-time-slots {
    margin-top: 20px;
}

.generated-time-slots h4 {
    color: #333;
    margin-bottom: 10px;
}

.generated-time-slots ul {
    list-style-type: none;
    padding: 0;
}

.generated-time-slots ul li {
    padding: 8px;
    background-color: #f1f1f1;
    border-radius: 4px;
    margin-bottom: 5px;
}
