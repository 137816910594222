/* General Styles */
.feedback-enquiry {
    padding: 20px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-color: #f8f9fa;
    border-radius: 8px;
    max-width: 100%; /* Full width to ensure consistent layout */
    margin: 20px auto;
}

.feedback-enquiry h2 {
    background: linear-gradient(90deg, #ff8a00, #e52e71);
    padding: 1.5rem;
    text-align: center;
    color: white;
    border-radius: 10px;
    font-size: 1.8rem;
    margin-bottom: 30px;
}

/* Filters Section */
.feedback-search-filters {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr)); /* Responsive grid */
    gap: 20px;
    margin-bottom: 20px;
}

.feedback-search-field {
    display: flex;
    flex-direction: column;
    width: 100%; /* Full width for fields */
}

.feedback-search-field label {
    margin-bottom: 5px;
    color: #555;
    font-weight: 500;
}

.feedback-search-field input,
.feedback-search-field select,
.feedback-search-field .react-datepicker-wrapper input {
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
    box-sizing: border-box;
    font-size: 14px;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
    width: 100%; /* Ensure inputs take full width */
}

.feedback-search-field input:focus,
.feedback-search-field select:focus,
.feedback-search-field .react-datepicker-wrapper input:focus {
    border-color: #007bff;
    box-shadow: 0 0 8px rgba(0, 123, 255, 0.25);
    outline: none;
}

/* Buttons */
.feedback-button-container {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-bottom: 20px;
}

.feedback-button {
    padding: 12px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s, box-shadow 0.3s;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.feedback-button:hover {
    background-color: #0056b3;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

/* Table Section */
.feedback-table-container {
    overflow-x: auto;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
}

.feedback-table {
    width: 100%;
    border-collapse: collapse;
}

.feedback-table th,
.feedback-table td {
    padding: 12px;
    border: 1px solid #ddd;
    text-align: left;
    font-size: 14px;
    color: #555;
}

.feedback-table th {
    background-color: #f2f2f2;
    color: #333;
    font-weight: 600;
}

.feedback-table tbody tr:nth-child(even) {
    background-color: #f8f9fa;
}

.feedback-table tbody tr:hover {
    background-color: #e9ecef;
}

.feedback-table a {
    color: #007bff;
    text-decoration: none;
}

.feedback-table a:hover {
    text-decoration: underline;
}

/* Action Buttons */
.feedback-manage-button,
.feedback-send-info-button {
    padding: 8px 12px;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s, box-shadow 0.3s;
    margin-right: 5px;
}

.feedback-manage-button {
    background-color: #28a745;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.feedback-manage-button:hover {
    background-color: #218838;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.feedback-send-info-button {
    background-color: #ffc107;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.feedback-send-info-button:hover {
    background-color: #e0a800;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

/* Attended and Attend Button Styles */
.feedback-attended-button {
    background-color: #28a745;
    color: white;
    cursor: not-allowed;
    border: none;
    padding: 8px 12px;
    border-radius: 5px;
    font-size: 14px;
}

.feedback-attend-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 8px 12px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, box-shadow 0.3s;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.feedback-attend-button:hover {
    background-color: #0056b3;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

@media(max-width:1300px){
  .feedback-enquiry {
    margin-left: 15%;
    width: 95%;
}
  

}
/* Responsive Styles */
@media screen and (max-width: 767px) {
    .feedback-search-filters {
        grid-template-columns: 1fr; /* Single column layout for small screens */
    }

    .feedback-table th, 
    .feedback-table td {
        font-size: 13px;
        padding: 10px;
    }

    .feedback-button {
        font-size: 13px;
        padding: 10px;
    }

    .feedback-manage-button,
    .feedback-send-info-button {
        font-size: 12px;
        padding: 8px 10px;
    }
}

@media screen and (max-width: 480px) {
    .feedback-enquiry h2 {
        font-size: 24px;
    }

    .feedback-button {
        font-size: 12px;
        padding: 10px;
    }

    .feedback-table th, 
    .feedback-table td {
        font-size: 12px;
        padding: 8px;
    }

    .feedback-manage-button,
    .feedback-send-info-button {
        font-size: 11px;
        padding: 6px 8px;
    }
}
