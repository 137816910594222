/* Container for the navbar */
.column-navbar-container {
  position: relative;
}

/* Navbar styles for desktop */
.column-navbar {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.Navbar {
  width: 200px;
  background: rgb(42, 63, 84);
  height: 100vh;
  padding-top: 5rem;
  padding-inline: 1rem;
  padding-bottom: 2rem;
  font-size: 1rem;
  font-weight: 600;
}

/* Mobile hamburger menu */
.hamburger-menu {
  display: none;
  font-size: 24px;
  color: white;
  cursor: pointer;
  margin: 10px;
}

/* Navbar item styles */
.nav-item {
  display: flex;
  flex-direction: column;
  padding: 12px;
  cursor: pointer;
  transition: background-color 0.2s;
  color: white;
}

.menu-item-header {
  display: flex;
  align-items: center;
}

.dropdown-icon {
  margin-left: auto;
}

.dropdown {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding-left: 20px;
  border-left: 1.5px solid #ccc;
}

#NavbarA,
#NavbarDropdown {
  color: white;
  text-decoration: none;
}

.menu-item-header {
  justify-content: space-between;
}

.menu-item-label {
  margin-right: 10px;
}

.dropdown-item-link{
  color: #ccc;
  margin-top: 10px;
}
.menu-item-label{
  margin-bottom: 20px;
}
.IconeInCOlumnNavbarHel{
  margin-top:-2px;
  margin-right: 20px;
}
.menu-item-label {
  margin-bottom: 6px;
}
/* Mobile view: Hide navbar and show hamburger */
@media (max-width: 1300px) {
  .column-navbar {
    display: none;
    background-color: rgb(42, 63, 84);
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 250px;
    z-index: 1000;
    transition: transform 0.3s ease;
   
  }

  .column-navbar.show {
    display: flex;
    transform: translateX(0);
    z-index: 2000;
    overflow: overlay;
  }

  .hamburger-menu {
    display: block;
    margin-left: 0px;
  }

  .menu-item-header {
    justify-content: space-between;
    padding-right: 10px;
  }

  .dropdown {
    padding-left: 10px;
  }
  .Navbar {
    width: 30px;
    background: rgb(42, 63, 84);
    height: 100vh;
    padding-top: 5rem;
    padding-inline: 1rem;
    padding-bottom: 2rem;
    font-size: 1rem;
    font-weight: 600;
    position: fixed;
  }
  
}

/* Tablet or mobile: Menu hides when not active */
@media (max-width: 600px) {
  .column-navbar {
    width: 200px;
    transform: translateX(-250px); /* Hidden off-screen */
  }
  .IconeInCOlumnNavbarHel{
    margin-top:-10px;
    margin-right: 20px;
  }

  .column-navbar.show {
    transform: translateX(0);
  }

  .menu-item-header {
    font-size: 14px;
  }

  .dropdown-item {
    font-size: 14px;
  }
  .Navbar {
    width: 30px;
    background: rgb(42, 63, 84);
    height: 100vh;
    padding-top: 5rem;
    padding-inline: 1rem;
    padding-bottom: 2rem;
    font-size: 1rem;
    font-weight: 600;
  }
}
