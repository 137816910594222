/* Main Container */
.user-unique__container {
    background-color: #f9f9f9;
    margin-inline: 1rem;
    border-radius: 10px;
    padding: 2rem 1rem 3rem 1rem;
    margin-top: 3rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

/* Heading */
.user-unique__heading {
    color: #FF9900;
    font-weight: 600;
    margin-bottom: 2rem;
    font-size: 2rem;
}

/* Buttons Container */
.user-unique__button-group {
    display: flex;
    justify-content: center;
    gap: 1rem;
}

/* Button Styling */
.user-unique__button {
    padding: 0.75rem 1.5rem;
    background-color: #337ab7;
    color: white;
    font-size: 1.1rem;
    font-weight: 600;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    text-transform: uppercase;
}

.user-unique__button:hover {
    background-color: #286090;
    box-shadow: 0 4px 10px rgba(0, 123, 255, 0.3);
}

/* Mobile and Responsive Adjustments */
@media (max-width: 768px) {
    .user-unique__button-group {
        flex-direction: column;
        gap: 1rem;
    }

    .user-unique__button {
        width: 100%;
        padding: 0.75rem;
        font-size: 1rem;
    }
}

@media (max-width: 480px) {
    .user-unique__heading {
        font-size: 1.75rem;
    }

    .user-unique__button {
        font-size: 0.9rem;
        padding: 0.75rem;
    }
}
