/* Main container styling */
.CRMBlog-container {
    width: 90%;
    margin: 0 auto;
    padding: 20px;
    background-color: #f8f9fa; /* Light background */
    border-radius: 8px;
    overflow-x: hidden; /* Prevent horizontal scrolling */
}

/* Header section styling */
.CRMBlog-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.CRMBlog-header h1 {
    color: #f49808; /* Orange color for "Blogs Master" */
    font-size: 1.8rem;
}

/* Back button styling */
.CRMBlog-back-button {
    background-color: #0062cc;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
    transition: background-color 0.3s ease; /* Smooth transition */
}

.CRMBlog-back-button:hover {
    background-color: #004085; /* Darker blue on hover */
}

/* Search section styling */
.CRMBlog-search {
    margin-bottom: 20px;
    padding: 10px 0;
}

.CRMBlog-search-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem; /* Space between elements */
}

.CRMBlog-search-row input,
.CRMBlog-search-row select {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    width: 25%;
}

.CRMBlog-search-row button {
    background-color: #0062cc;
    color: white;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    border: none;
    font-weight: bold;
    transition: background-color 0.3s ease;
}

.CRMBlog-search-row button:hover {
    background-color: #004085;
}

/* Table container styling */
.CRMBlog-table-container {
    max-width: 100%;
    overflow-x: hidden; /* Prevent horizontal scrolling */
    overflow-y: auto;
    border-radius: 8px;
    background-color: white;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

/* Table styling */
.CRMBlog-table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed; /* Responsive table */
    margin-top: 10px;
}

.CRMBlog-table th,
.CRMBlog-table td {
    padding: 15px;
    text-align: left;
    border-bottom: 1px solid #dee2e6;
    word-wrap: break-word; /* Text wrapping within cells */
    font-size: 14px;
}

.CRMBlog-table th {
    background-color: #f8f9fa;
    color: #343a40; /* Dark text color */
    font-weight: bold;
}

/* Button styling within the table */
.CRMBlog-button {
    display: block;
    margin-bottom: 5px;
    padding: 8px 12px;
    color: white;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    font-weight: bold;
    text-align: center;
    transition: background-color 0.3s ease;
}

.CRMBlog-button.select {
    background-color: #0056b3;
}

.CRMBlog-button.preview {
    background-color: #ffc107;
}

.CRMBlog-button.deactivate {
    background-color: #dc3545;
}

.CRMBlog-button.update-sequence {
    background-color: #28a745;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 3px;
    cursor: pointer;
    font-weight: bold;
    margin-top: 10px;
}

/* Modal overlay styling */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

/* Modal content styling */
.modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    width: 90%;
    max-width: 600px;
    max-height: 90vh; /* Ensure the modal doesn't exceed the viewport height */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    overflow-y: auto; /* Scrollable content */
    position: relative;
}

/* Close button styling */
.modal-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: transparent;
    border: none;
    font-size: 24px;
    cursor: pointer;
    font-weight: bold;
}

.modal-close-button:hover {
    color: red;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .CRMBlog-search-row {
        flex-direction: column;
    }

    .CRMBlog-search-row input,
    .CRMBlog-search-row select,
    .CRMBlog-search-row button {
        width: 100%;
    }

    .modal-content {
        width: 95%; /* Slightly wider on smaller screens */
        max-width: none;
    }
}
