/* Container Styles */
.blogs-crm-container {
    width: 100%;
    max-width: 1200px;
    margin: 2rem auto;
    padding: 2rem;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  /* Header Styles */
  .blogs-crm-header h1 {
    font-size: 2.5rem;
    color: #343a40;
    font-weight: 700;
    margin-bottom: 2rem;
  }
  
  /* Action Buttons Container */
  .blogs-crm-actions {
    display: flex;
    justify-content: center;
    gap: 1.5rem;
    flex-wrap: wrap;
    margin-top: 2rem;
  }
  
  .blogs-crm-action-button {
    text-align: center;
  }
  
  /* Button Styles */
  .blogs-crm-btn {
    padding: 0.75rem 2rem;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 1.1rem;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
  }
  
  .blogs-crm-btn:hover {
    background-color: #0056b3;
    box-shadow: 0 2px 4px rgba(0, 123, 255, 0.3);
  }
  
  /* Delete Button Special Style */
  .delete-btn {
    background-color: #dc3545;
  }
  
  .delete-btn:hover {
    background-color: #c82333;
    box-shadow: 0 2px 4px rgba(220, 53, 69, 0.3);
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .blogs-crm-header h1 {
      font-size: 2rem;
    }
  
    .blogs-crm-btn {
      font-size: 1rem;
      padding: 0.65rem 1.5rem;
    }
  
    .blogs-crm-actions {
      flex-direction: column;
      gap: 1rem;
    }
  }
  
  @media (max-width: 480px) {
    .blogs-crm-header h1 {
      font-size: 1.8rem;
    }
  
    .blogs-crm-btn {
      font-size: 0.9rem;
      padding: 0.6rem 1.2rem;
    }
  }
  