/* General container for the Delete Article page */
.DeleteArticleTopHeadingAndBackButton {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: #f8f9fa;
    border-bottom: 2px solid #dee2e6;
}

.DeleteArticleTopHeadingAndBackButton h2 {
    margin: 0;
    font-size: 24px;
    color: #333;
}

.DeleteArticleTopHeadingAndBackButton button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.DeleteArticleTopHeadingAndBackButton button:hover {
    background-color: #0056b3;
}

/* Filter section styling */
.DeleteArticleFilter {
    padding: 20px;
    background-color: #ffffff;
    border-bottom: 2px solid #dee2e6;
    overflow-x: hidden;
}

.FilterMain input {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ced4da;
    border-radius: 5px;
}

/* Table styling */
.DeleteArticleTable {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.DeleteArticleTable thead {
    background-color: #007bff;
    color: white;
}

.DeleteArticleTable thead th {
    padding: 10px;
    text-align: left;
    font-size: 16px;
}

.DeleteArticleTable tbody tr {
    border-bottom: 1px solid #dee2e6;
}

.DeleteArticleTable tbody td {
    padding: 10px;
    font-size: 14px;
}

.DeleteArticleTable tbody img {
    max-width: 100px;
    border-radius: 5px;
}

/* Delete button styling */
.DeleteArticleTable tbody td button {
    background-color: #dc3545;
    color: white;
    border: none;
    padding: 5px 10px;
    font-size: 14px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.DeleteArticleTable tbody td button:hover {
    background-color: #c82333;
}

/* Responsive design */
@media (max-width: 768px) {
    .DeleteArticleTopHeadingAndBackButton {
        flex-direction: column;
        align-items: flex-start;
    }

    .DeleteArticleFilter {
        padding: 10px;
    }

    .FilterMain input {
        font-size: 14px;
    }

    .DeleteArticleTable thead th, .DeleteArticleTable tbody td {
        font-size: 12px;
    }
}
